import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../components/contact-info/contact-info";
import SEO from "../../components/seo/seo";
import List from "../../components/custom-widgets/list";
import StretchedLinkCards from "../../components/card/stretched-link-cards";
import BestBanksDefault from "../../components/best-banks/best-banks-default";

//helper functions
import showMktoForm from "../../helpers/showMktoForm";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const CommercialLineOfCredit = () => {
  const title = "Commercial Line Of Credit";

  const description =
    "Need a Commercial Line of Credit? At WaFd Bank we can customize a LOC to your business needs with competitive rates. Contact our commercial bankers today.";

  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/commercial-banking/commercial-line-of-credit/hero-commercial-loc-02-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/commercial-banking/commercial-line-of-credit/hero-commercial-loc-02-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/commercial-banking/commercial-line-of-credit/hero-commercial-loc-02-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/commercial-banking/commercial-line-of-credit/hero-commercial-loc-02-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/commercial-banking/commercial-line-of-credit/hero-commercial-loc-02-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/commercial-banking/commercial-line-of-credit/hero-commercial-loc-02-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/commercial-banking/commercial-line-of-credit/hero-commercial-loc-02-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
      card1: file(relativePath: { eq: "cards/thumbnail-wafd-client-connect-062923.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 600, transformOptions: { cropFocus: CENTER }, layout: CONSTRAINED)
        }
      }
      card2: file(relativePath: { eq: "cards/thumbnail-business-analyzed-checking-062923.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 600, transformOptions: { cropFocus: CENTER }, layout: CONSTRAINED)
        }
      }
      card3: file(relativePath: { eq: "cards/thumbnail-treasury-management-062923.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 600, transformOptions: { cropFocus: CENTER }, layout: CONSTRAINED)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "commercial-line-of-credit-hero",
    ...getHeroImgVariables(imgData),
    altText: "Banker talking with client in office",
    chevronBgClass: "bg-dark-green-01",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Supporting Your Cash Flow Needs"
          }
        },
        {
          id: 2,
          button: {
            id: "commercial-line-of-credit-hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us",
            icon: {
              position: "right",
              lib: "fas",
              name: "arrow-right",
              class: "float-right mt-1 ml-3"
            }
          }
        }
      ]
    }
  };

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/commercial-line-of-credit"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-commercial-loc-02-250.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Commercial Line of Credit"
    }
  ];
  const threeCardsData = {
    sectionClass: "bg-white py-0",
    cards: [
      {
        id: "commercial-line-client-connect-link",
        url: "/commercial-banking/commercial-client-connect",
        title: "WAFD Client Connect",
        showTextArrow: true,
        text: "Securely send documents to your commercial banker using WAFD Client Connect. Once you're an existing client, you can apply for new commercial lending products securely and easily.",
        image: {
          imgVariable: imgData.card1.childImageSharp.gatsbyImageData,
          altText: "Businesswoman sitting in an office with a laptop"
        }
      },
      {
        id: "commercial-line-business-checking-account-link",
        url: "/business-banking/business-checking-account",
        title: "Business Checking",
        showTextArrow: true,
        text: "Combine your line of credit with a WaFd Bank business deposit account to reduce interest expense and manage cash requirements through your operating account with an automated sweep.",
        image: {
          imgVariable: imgData.card2.childImageSharp.gatsbyImageData,
          altText: "Businesswoman in office using phone"
        }
      },
      {
        id: "commercial-line-treasury-management-link",
        url: "/commercial-banking/treasury-management-services",
        title: "Treasury Management",
        showTextArrow: true,
        text: "Explore WaFd's Treasury Management Solutions for more ways to improve the management of your cash resources and tasks including receivables, payables, and security and fraud.",
        image: {
          imgVariable: imgData.card3.childImageSharp.gatsbyImageData,
          altText: "Senior businessman on mobile phone"
        }
      }
    ]
  };

  const atWafdBankListData = {
    accountName: "line-of-credit-list",
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "We understand not all businesses are the same, we can customize a LOC to your business needs to finance your growth"
      },
      {
        id: 2,
        text: " We offer competitive interest rates and strong Treasury Management products to provide the best banking experience"
      },
      {
        id: 3,
        text: "We take the time to understand your business and create long-term, trusted relationships"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container py-0">
        <div className="row">
          <div className="col-md-6">
            <h1>Commercial Lines of Credit</h1>
            <h3>
              Customized commercial lines of credit (LOC) provide short-term and flexible working capital to help
              businesses grow and manage cash requirements.
            </h3>
          </div>
          <div className="col-md-6">
            <h3>At WaFd Bank</h3>
            <List {...atWafdBankListData} />
          </div>
        </div>
      </section>
      <StretchedLinkCards {...threeCardsData} />
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default CommercialLineOfCredit;
